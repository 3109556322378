<template>
  <div class="container">
    <PublicHeader :totalName="$t('public').kefu" />
    <div class="content">
      <img src="@/assets/new/banner1.png" alt="" />

      <van-cell
        :title="item"
        v-for="item in list"
        :key="item"
        @click="handleClick"
      >
        <template #right-icon>
          <img src="@/assets/new/kefu.png" class="icon" alt="" />
        </template>
      </van-cell>
    </div>
  </div>
</template>

<script>
import PublicHeader from '@/components/publicHeaderNew'
import { mapGetters } from 'vuex'
export default {
  components: {
    PublicHeader
  },
  computed: {
    ...mapGetters(['basicConfig'])
  },
  data() {
    return {
      list: [
        'dịch vụ khách hàng',
        'Customer Service',
        'บริการลูกค้า',
        '顧客サービス',
        '고객 서비스',
        '客服',
        '客服(繁)'
      ]
    }
  },
  methods: {
    handleClick() {
      window.open(this.basicConfig.ServiceUrl)
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  background: #fff;
  padding-top: 44px;
  min-height: 100vh;
  .content {
    img {
      width: 345px;
      display: block;
      margin: 0 auto;
      margin-bottom: 10px;
    }
    margin: 10px auto 0;
    .icon {
      width: 22px;
      height: 20px;
    }
  }
}
</style>
